.userPanel {
  display: flex;
  margin-top: 170px;
}


.content {
  padding: 0 20px;
}

.contentZ {
  width: 80%;
  min-height: 860px;
}

.contentFull {
  width: 100%;
}

.catLabel {
  display: block;
  padding: 30px 0px 0px 0px;
  font-size: 19px;
}

.uploadSection {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 3px solid #dbdbdb;
  border-radius: 5px;
  margin: 0 153px;
  border-left: 63px solid #3fa95b !important;
}

.photoSection {
  display: flex;
  flex-wrap: wrap;
}



.storySection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 62%;
  padding: 0 8%;
  margin: 50px 153px;
  padding-bottom: 50px;
  border-radius: 10px;
  box-shadow: 4px 2px 11px rgba(0, 0, 0, .1);
  border-left: 63px solid #4c8bec !important;
  background: #ffffffd1;
  border: 1px solid #c9c9c9d1;
}


.categorySection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 62%;
  padding: 0 8%;
  margin: 50px 153px;
  padding-bottom: 50px;
  border-radius: 10px;
  box-shadow: 4px 2px 11px rgba(0, 0, 0, .1);
  border-left: 63px solid #4c8bec !important;
  background: #ffffffd1;
  border: 1px solid #c9c9c9d1;
}

.categorySection h3 {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 40px;
  display: block;
  color: #4c8bec;
  padding: 30px 12px;
}

.photoItem {
  min-width: 219px;
  max-width: 219px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.photoItem:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.photoItem img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.photoItem .deleteButton {
  display: block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.photoItem .deleteButton:hover {
  background-color: #d32f2f;
}

.modalContent {
  width: 80%;
  height: 80%;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border: 1px solid #888;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coverImage {
  background-color: #fff;
  height: 100px;
  margin: 10px;
  width: 100px;
  border: 2px solid black;
}

.coverImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.modalContent .photoContainer{
  display: flex;
  flex-direction: row;
  margin-top: 80px;
}

.modalContent .photoItem{
  cursor: pointer;
  position: relative;
}


.modalContent .arrowLeft {
  position: absolute;
  right: 47px;
  top: -50px;
  height: 30px;
  width: 30px;
  font-weight: bold;
  
}

.modalContent .arrowRight {
  position: absolute;
  top: -50px;
  right: 0;
  height: 30px;
  width: 30px;
  font-weight: bold;
}



.categoryActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 20px;
}

.photoContainerOut{
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 40px;
}

.photoContainerOut .photoItem{
  position: relative;
}

.flag {
  position: absolute;
  background-color: #4c8bec;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  top: 20px;
  left: 20px;  
}

.editPhotoButton{
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  padding: 5px 10px;
  transition: background-color .2s;
  background-color: gray;
}


.saveButton{
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4c8bec;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hidden {
  display: none;
}