.storyContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}


.categoryContainer {
  position: relative;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.categoryStory {
  margin: 0px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}


.categoryImage {
  margin-top: 95px;
  margin-bottom: 12px;
  border-radius: 50%;
  height: 100px;
  cursor: pointer;
  object-fit: cover;
  width: 100px;
  border: 5px solid #4caf50
}

.modal {
  z-index: 999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 95%);
}

.image {
  height: 100%;
  object-fit: cover;
  width: auto;
  border: 1px solid #292929;
  border-radius: 19px;
}

.progressBarContainer {
  display: flex;
  height: 2px;
  justify-content: space-between;
  margin-bottom: 25px;
  position: absolute;
  top: 22px;
  width: 20%;
}

.progressBar {
  background-color: #939393;
  height: 5px;
  margin: 0 8px;
  position: relative;
  width: 100%;
}

.progressBarFill {
  position: absolute;
  height: 100%;
  background-color: #ffffff;
}


.controls {
  position: absolute;
  width: 732px;
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  padding: 0 20px;
}

.controlIcon {
  background: #8d8d8dfa;
  padding: 10px;
  border-radius: 50%;
  color: white;
  font-size: 33px;
  cursor: pointer;
  font-weight: bold;
}

.playPauseButton {
  color: #b5b5b5;
  cursor: pointer;
  font-size: 33px;
  right: 121px;
  position: absolute;
  top: -295px;
}

.playIcon {
  color: #ffffff;
  cursor: pointer;
  font-size: 33px;
  font-weight: 700;
  padding: 10px;
  background: #b9b9b959;
  border-radius: 27%;
}

.acessarButton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  right: 121px;
  top: -230px;
  position: absolute;
}


@media (max-width: 768px) {
  .image {
    height: auto;
    width: 75%;
  }

  .controls {
    width: 93%;
  }

  .controlIcon {
    font-size: 16px;
    padding: 7px;
  }

  .progressBarContainer {
    width: 57%;
    position: unset;
    top: unset;
  }

  .playPauseButton {
    top: unset;
    right: unset;
    left: 65px;
    bottom: -236px;
  }

  .acessarButton {
    top: unset;
    bottom: -230px;
    right: 63px;
    font-size: 19px;
  }

  .categoryStory:nth-child(2) {
    display: none;
  }
}