.categories {
  display: flex;
  justify-content: space-around; /* Distribui as seções de categoria igualmente */
  margin-top: 170px;
}

.categorySection {
  padding: 30px; /* Aumenta o padding para dar mais espaço */
  margin: 20px;
  background-color: #f8f8f8; /* Suaviza a cor de fundo para um cinza mais claro */
  border: 1px solid #ddd; /* Suaviza a borda para um cinza mais claro */
  border-radius: 10px; /* Aumenta o border-radius para suavizar os cantos */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Distribui os itens da categoria igualmente */
}

.categoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 50%;
  margin: 10px;
  padding: 15px; /* Aumenta o padding para dar mais espaço */
  border: 1px solid #ccc;
  border-radius: 10px; /* Aumenta o border-radius para suavizar os cantos */
  background-color: #f9f9f9;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform .2s;
  list-style: none;
}

 @media screen and (max-width: 1390px) {
  .categoryItem {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .categoryItem {
    flex-direction: column;
  }
}

.categoryItem:hover {
  transform: translateY(-5px);
  box-shadow: 2px 5px 10px rgba(0, 0, 0, .2);
}

.categoryItem a {
  text-decoration: none;
  color: #333;
  font-weight: 600; /* Aumenta a espessura da fonte para melhor legibilidade */
}
.content {
  padding: 0 20px;
}

.contentZ {
  min-height: 860px;
  width: 80%;
}

.contentFull {
  width: 100%;
}

.categories h1{
  margin-left: 1em;
}


.creatingCategory{
  display: flex;
  margin-bottom: 40px;
  margin-left: 3em;
  flex-direction: column;
  max-width: 50%;
}

.editCategory{
  background-color: #4c8bec;
  margin-bottom: 10px;
  cursor: pointer;
}

.creatingCategoryButton {
  background-color: #4c8bec;
  cursor: pointer;
}

.deleteCategory {
  background-color: #d32f2f;
  cursor: pointer;
}
.nameSection{
  margin-left: 1em;

}
.editCategoryNameSection {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
}