/* Header.module.css */
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffff; /* Verde Principal */
    max-width: 1920px;
    margin: 0 auto;
    padding: 10px  107px;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 20;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(187 187 187 / 75%);
    -moz-box-shadow: 0px 3px 10px 0px rgba(187 187 187 / 75%);
    box-shadow: 0px 3px 10px 0px rgba(187 187 187 / 75%);
  }

  .demo{
    padding: 10px 0!important;
  }

  .sobre,.demo{
    color: #000;
  }

  .sobre{
    text-decoration: underline!important;
  }

  .cadastro{
    color: #007bff;
    background: #f1f0f0;
    margin-left: 34px!important;
  }
  
  .logout{
    cursor: pointer;
  }

  .login{
    color: white;
    background-color: #4C8BEC;
  }

  @media(min-width:1024px) {
    .right{
      display: flex;
      justify-content: center;
      text-decoration: none;
      color: black;
      font-weight: bold;
      text-transform: uppercase;
    }
    .right a{
      padding: 10px 20px;
      font-size: 22px;
      margin: 0 10px;
      text-decoration: none;
      border-radius: 27px;  
    }
    .center p{
      color: #515151;
      max-width: 272px;
      font-size: 12px;
      letter-spacing: 10px;
    }
  }

  @media(max-width:1920px) {
    .header{
        max-width: 100%;
        padding: 0 30px;
    }
    .right{
        padding: 0 60px;
    }

}

.right{
  width: 50%;
}
.left,.center{
  width: 20%;
}


.right{
    text-align: right;
    
}

  .center {
  
    text-align: center;
  }
  
   a img {
    height: 106px;
    padding: 10px 0px;
    width: auto; /* Ajuste o tamanho máximo do logo conforme necessário */
  }
  

  
  .link {
    width: 300px;
    float: right;
    text-align: center;
    padding: 5px;
    color: #fff; /* Cor do texto do link */
    text-decoration: none;
    padding: 5px 10px;
    border: 2px solid #fff; /* Borda para destacar o link */
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .link:hover {
    background-color: #fff; /* Cor de fundo quando o link estiver em hover */
    color: #f9f9f9; /* Cor do texto do link em hover */
  }


  

@media(max-width:1024px) {
  .center{
    display: none;
  }
  .right{
    line-height: 42px;
  }
  .right a{  
     margin: 30px 20px;
  }
  a img{
    height: 5em;
  }
}

.hamburger {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
}

.menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80%;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  overflow: auto;
  z-index: 1000;
}

.menu a {
  display: block;
  margin-bottom: 1rem;
  color: #333;
  text-decoration: none;
}

.menu button {
  background: none;
  border: none;
  font-size: 2.5rem;
  position: absolute;
  color: #4c8bec;
  right: 1rem;
  top: 1rem;
}

@media (max-width: 768px) {
  .hamburger {
    color: #4c8bec;
    position: absolute;
    right: 90px;
    font-weight: bold;
    display: block;
  }

  .menu {
    display: block!important;
  }

  .right {
    display: none!important;
  }
  .login{
    background-color: white;
  }
}