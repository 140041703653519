/* Footer.module.css */
.footer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: row;
  padding-top: 34px;
  border-top:0.5px solid #c1c1c1;
  max-width: 98%;
  margin-top: 90px;
  padding-bottom: 45px;
  }
  
  .left {
    flex: 1; /* Ocupa todo o espaço disponível à esquerda */
  }
  
.center p, .right p, .right a{
    color: #000000;
    text-align: left;

}

.right .tel{
    line-height: 0;
}

  .navList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .navItem {
    margin-right: 10px;
    padding: 5px;
    cursor: pointer;
  }
  
  .navItem:last-child {
    margin-right: 0;
  }
  
  .navItem a {
    font-weight: bolder;
    text-decoration: none;
    color: #000000; /* Cor do texto do link */
    padding: 5px;
    border-radius: 5px;
  }
  
  .navItem a:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Cor de fundo em hover */
  }
  
  .center {
    flex: 1; /* Ocupa todo o espaço disponível no centro */
    text-align: center;
  }
  
  .center img {
    max-width: 200px; /* Ajuste o tamanho máximo do logo conforme necessário */
  }
  
  .right {
    flex: 1; /* Ocupa todo o espaço disponível à direita */
  }
  
  /* Estilos para o botão do WhatsApp */
  .whatsappButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366; /* Cor do botão do WhatsApp */
    color: #ffffff!important;
    font-size: 32px;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
  }
  
  .whatsappButton:hover {
    background-color: #128c7e; /* Cor do botão do WhatsApp em hover */
  }
  
  /* Estilos para o ícone do WhatsApp */
  .whatsappIcon {
    margin-top: 2px;
  }
    

  @media (max-width: 768px) {
    .footer{
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      justify-self: start;
    }
    .left, .center, .right {
      padding-bottom: 35px;
    }  
    .navList{
      min-width: 275px;
    }
  }