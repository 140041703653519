.loginWrapper button:first-child {
  margin-top: 10px;
}

.loginWrapper button {
  border-radius: 27px;
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: white;
  background-color: #007bff;
  font-size: 22px;
  width: 100%;
  height: 42px;

  text-decoration: none;
}

.loginWrapper .message {
  margin-top: 10px;
  color: #d32f2f;
  max-width: 200px;
}

.forgotPassword {
  background-color: #d32f2f !important;
  border: none !important;
  font-weight: 500 !important;
  color: white !important;
  width: 100% !important;
  font-size: 16px !important;
  text-decoration: none !important;
}


.inputGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 20px;
}


.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  max-width: 33vw;
}


@media screen and (max-width: 768px) {
  .loginWrapper {
    max-width: 80vw;
  }
}