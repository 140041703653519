
@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('./assets//fonts/Livvic-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Livvic', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  justify-content: start;
  min-height: 1200px;
  background-color: #fff;
}


input {
  margin: 5px 0;
  padding: 8px 16px;
  border: 1px solid #ccc;
}


button {
  padding: 8px 16px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 22px;
}