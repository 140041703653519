.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 20px;
    text-align: center;
    padding-bottom: 100px;
    margin-top: 100px;
  }
  
  .title {
    font-size: 4.5em;
    color: #4c8bec;
    font-weight: bolder;
    margin-top: 200px;
  }
  
  .description {
    font-size: 1.5em;
    color: #000000;
    margin-top: 20px;
    line-height: 35px;
  }
  
  .storyContainerHome {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding: 10%;
  }
  
  
  .bulletPoints {
    list-style-type: disc;
    DISPLAY: FLEX;
    FLEX-DIRECTION: COLUMN;
    ALIGN-ITEMS: CENTER;
    JUSTIFY-CONTENT: CENTER;
    MARGIN: 50PX 0;
  }
  .bulletPoints li {
    margin-bottom: 10px;
  }
  
   @media (max-width: 768px) {
    .container{
      margin-top: unset;
    }
    .imgFuncionamento {
      width: 90vw;
      height: auto;
    }
    .bulletPoints li{
      margin-bottom: 10px;
      padding: 25px 33px;
      text-align: left;
    }
    .description {
      font-size: 1.2em;
      padding: 0 25px;
    }
    .title {
      font-size: 3em;
    }
    .bulletPoints{
      margin-left: 18px;
    }
  }
  