.MeusDados {
    display: flex;
    margin-top: 170px;
    padding-bottom: 100px;
}



.MeusDados .title {
    font-weight: bold;
    margin-bottom: 20px;
}

.MeusDados .field {
    font-size: 18px;
    margin-bottom: 10px;
}

.MeusDados .editButton {
    background-color: #4c8bec;
    cursor: pointer;
    padding: 8px 16px;
}

.MeusDados .editButtonToken {
    background-color: #f1c026;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 18px;
}



.field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inputGroup {
    display: flex;
    align-items: center;
}

.inputGroup label {
    margin-left: 20px;
    text-align: left;
    margin-right: 10px;
    width: 100px;
}

.inputField {
    min-width: 260px;
}

.inputFieldToken{
    min-width: 80%;
}

.inputField,
.editButton {
    flex: 1;

    /* Ajuste conforme necessário */
}

.MeusDados .inputField {
    margin-left: 10px;
    margin-right: 10px;
}


.MeusDados .message, .tokenMsg {
    color: green;
    font-weight: bold;
}


.content {
    padding: 0 20px;
    margin-left: 3em;
}

.contentZ {
    width: 80%;
    min-height: 860px;
}

.contentFull {
    width: 100%;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    animation: spin 1s linear infinite;
    color: white;
}

.icon {
    margin-right: 10px;
}

.atentionToken{
    color: #d32f2f;
    font-weight: bold;
    font-size: 18px;
}

.copyButton {
    background-color: #3fa95b;
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    margin-left: 10px;
    margin-right: 30px;
  }