.uploadContainer {
  align-items: center;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  /* Alinhamento vertical ao centro */
  margin: 0 auto;
  /* Centraliza o container na horizontal */
  max-width: 600px;
  /* Limita a largura do container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Adiciona uma sombra suave */
}

.fileInput {
  margin: 20px 0;
  /* Aumenta a margem vertical para dar mais espaço */
  padding: 15px;
  /* Aumenta o padding para melhorar a sensação tátil */
  border: #3fa95b 4px solid;
  /* Adiciona uma borda para definir o input */
  border-radius: 4px;
  /* Suaviza os cantos do input */
  font-size: 16px;
  /* Aumenta o tamanho da fonte para melhor legibilidade */
}

.button {
  margin-top: 20px;
  /* Aumenta a margem superior para dar mais espaço */
  padding: 15px 30px;
  /* Aumenta o padding para melhorar a sensação tátil */
  background-color: #3fa95b;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  /* Suaviza os cantos do botão */
  font-size: 16px;
  /* Aumenta o tamanho da fonte para melhor legibilidade */
  transition: background-color 0.3s ease;
  /* Adiciona uma transição suave ao mudar a cor de fundo */
}

.button:hover {
  background-color: #2f7e44;
  /* Escurece a cor de fundo ao passar o mouse */
}

.message {
  margin-top: 20px;
  /* Aumenta a margem superior para dar mais espaço */
  color: #d32f2f;
  font-size: 16px;
  /* Aumenta o tamanho da fonte para melhor legibilidade */
}

.container h2 {
  display: block;
  padding: 30px;
  font-size: 82px;
}

.categorySelect {
  height: 30px; 
  padding: 0 10px; 
  border: #3fa95b 4px solid;
  margin-bottom: 20px;
}

.ativaCheckbox {
  margin-left: 5px; 
}

.uploadContainer input:last-child{
  margin: 20px 0;
}