  .menu {
      border: 0.2px solid #dddddd;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 100px;
      display: block;
      width: 15%;
      background-color: #f5f5f5;
      padding: 20px;
      box-shadow: 4px 2px 11px rgba(0, 0, 0, .1);
  }

  .menuIsClosed {
      display: none;
  }


  .userInfo {
      cursor: pointer;
      margin-bottom: 20px;
      display: flex;
  }

  .userInfo h3 {
      margin: 0;
      padding-left: 20px;
      padding-top: 5px;
      font-size: 20px;
  }

  .userInfo h3 a {
      text-decoration: none;
      color: #000;
  }

  .userInfo p {
      margin: 5px 0;
      color: #888;
  }
  

  .active {
    color: #4c8bec;
    border-left: 7px solid #4c8bec;
    padding-left: 18px;
  }

  .active h3 a{
    color: #4c8bec;
  }