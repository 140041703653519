.registerWrapper .input {
  margin: 5px 0;
  padding: 8px 16px;
  border: 1px solid #ccc;
}

.registerWrapper .button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 42px;
}

.registerWrapper .message {
  margin-top: 10px;
  color: #d32f2f;
  max-width: 200px;
}


.registerWrapper .inputGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.registerWrapper .inputGroup input {
  width: 80%;
}

.registerWrapper .icon {
  font-size: 20px;
}

.registerWrapper button {
  cursor: pointer;
}


.registerWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  max-width: 33vw;
}


@media screen and (max-width: 768px) {
  .registerWrapper {
    max-width: 80vw;
  }
}

.registerWrapper .error {
  border: 1px solid #d32f2f;
}